<template>
  <v-container>

    <AbordajeInterno></AbordajeInterno>
  </v-container>
</template>
<script>
import AbordajeInterno from "@/components/etapas/AbordajeInterno.vue";
export default {
  components: { AbordajeInterno },
};
</script>