import { render, staticRenderFns } from "./FoliosComponente.vue?vue&type=template&id=f50381be&scoped=true&"
import script from "./FoliosComponente.vue?vue&type=script&lang=js&"
export * from "./FoliosComponente.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f50381be",
  null
  
)

export default component.exports